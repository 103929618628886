.App {
  /*text-align: center;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-logo-static {
  margin-bottom: 0;
  margin-top: .7em;
}

.App-header {
  background-color: #1199D8;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
  z-index: 10;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logout-wrapper {
  right: 10px;
  display: inline;
  position: absolute;
  clear: both;
}


.login-wrapper {
  height: 96.5vh;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(17, 153, 216, 0.52), rgba(117, 19, 93, 0.73)), url('./assets/login_splash.jpg');
  background-size: cover;

}

.login-card {
  background-color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.ui.secondary.pointing.menu a:first-child {
  margin-left: auto !important;
}

.ui.secondary.pointing.menu a:last-child {
  margin-right: auto !important;
}

.sign-in-divider {
  padding-top: 7px;
  padding-bottom: 7px;
}

.item.highlightOver:hover {
  background-color: #1199D8 !important;
  cursor: pointer !important;
  color: white !important;
}

div.ui.pointing.secondary > a.active {
  background-color: #1199D8 !important;
  color: white !important;
}

#tabHeader { /* Header "tab" element in manage subscription */
  background-color: #b6cdd4 !important;
  color: #101010 !important;
  font-weight: bold;
  /*background-color: red !important;*/
}
